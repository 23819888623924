import "./Dashboard.css";
import { useState } from "react";
import Map from "../Map/Map";
import Filters from "../Filters/Filters.js";
import { FiltersContext } from "../../contexts/FiltersContext.js";
import { municipios } from "../../helpers/helpers.js";

export default function Dashboard() {
  const random = Math.floor(Math.random() * municipios.length);
  const [filters, setFilters] = useState({
    municipio: municipios[random],
    tu: 1,
    rh: 0,
    cuenca: "media",
  });

  return (
    <div className="dashboard">
      <FiltersContext.Provider
        value={{
          filters,
          setFilters,
        }}
      >
        <Filters />
        <Map />
      </FiltersContext.Provider>
    </div>
  );
}
