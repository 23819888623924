import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import Dashboard from "./components/Dashboard/Dashboard";
import ProjectDetailsSection from "./components/ProjectDetailsSection/ProjectDetailsSection";

function App() {
  return (
    <div className="App">
      <Navigation />
      <ProjectDetailsSection />
      <Dashboard />
    </div>
  );
}

export default App;
