import React, { useRef, useEffect, useContext } from "react";
import "./PointsLayerOverlay.css";
import { GeoJSON, LayersControl } from "react-leaflet";
import { useGetPolygonParameter } from "../../../hooks/useGetPolygonParameter.js";
import { FiltersContext } from "../../../contexts/FiltersContext.js";
import L from "leaflet";

const PointsLayerOverlay = ({ name, rpcName, checked }) => {
  const polygonRef = useRef(null);
  const { filters } = useContext(FiltersContext);

  const polygon = useGetPolygonParameter(filters, rpcName);

  const customIcon = L.divIcon({
    className: "custom-icon",
    html: `
      <div class="icon-container">
        <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="10" class="circle-icon" />
        </svg>
      </div>
    `,
    iconSize: [25, 25],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16],
  });

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
    }
  };

  const onPointClick = () => {};
  useEffect(() => {
    const layer = polygonRef.current;
    if (layer) {
      if (polygon) {
        layer.clearLayers().addData(polygon);
      }
    }
  }, [polygon]);

  return (
    <LayersControl.Overlay name={name} checked={checked ? true : false}>
      <GeoJSON
        data={polygon}
        ref={polygonRef}
        onEachFeature={onEachFeature}
        onclick={onPointClick}
        pointToLayer={(feature, latlng) => {
          return L.marker(latlng, { icon: customIcon });
        }}
      />
    </LayersControl.Overlay>
  );
};

export default PointsLayerOverlay;
