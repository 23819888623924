import "./Map.css";
import React, { useRef } from "react";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import LayerOverlay from "./LayerOverlay/LayerOverlay";
import PointsLayerOverlay from "./PointsLayerOverlay/PointsLayerOverlay";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = () => {
  const mapRef = useRef(null);
  const latitude = 25.66328;
  const longitude = -100.31511;

  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={15}
      ref={mapRef}
      className="map"
    >
      {/* <MyComponent /> */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'"
      />
      <LayersControl position="topright">
        <LayerOverlay polygonName="tramo_urbano" name="Tramo urbano" checked />
        <LayerOverlay polygonName="tramo_urbano" name="Buffer 1km" />
        <LayerOverlay
          polygonName="buffer_1km_tu"
          name="Buffer 1km - Tramo Urbano"
        />
        <PointsLayerOverlay
          name="Observaciones de Biodiversidad"
          rpcName="get_points_dynamic"
          checked
        />
        <PointsLayerOverlay
          name="Unidades Económicas"
          rpcName="get_denue_points"
        />
        <LayerOverlay
          polygonName="tres_segmentos"
          name="Tres segmentos"
          color="purple"
        />
        <LayerOverlay
          polygonName="red_hidrografica_orden_6_7"
          name="Red Hidrográfica orden 6 y 7"
        />
        <LayerOverlay
          polygonName="red_hidrografica_orden_5_7"
          name="Red Hidrográfica orden 5 y 7"
        />
        <LayerOverlay
          polygonName="anps_rh24Bf"
          name="Áreas naturales protegidas"
        />
        <PointsLayerOverlay
          name="Caracterización Suelo TU"
          rpcName="get_suelo_y_vegetacion"
        />
      </LayersControl>
    </MapContainer>
  );
};

export default Map;
