import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function ProblematicasAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>PROBLEMÁTICAS PRINCIPALES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            <li>
              Tiraderos de basura y escombro vinculados a poblaciones con
              derechos vulnerados y a los límites intermunicipales.
            </li>
            <li>
              Abandono negligente de residuos de la construcción y demolición
              asociados a obras viales recientes y a infraestructuras averiadas.{" "}
            </li>
            <li>Descargas de aguas negras.</li>
            <li>
              Población vecina excluida del servicio regular de recolección de
              residuos.
            </li>
            <li>
              Presencia y avance de grandes parches de vegetación invasora,
              principalmente carrizo.
            </li>
          </ol>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>
            LA MAYORÍA DE LA VEGETACIÓN GENERA BENEFICIOS.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            La mayoría de las comunidades vegetales son propias de la región. La
            vegetación secundaria y algunas especies exóticas retienen los
            taludes evitando deslaves. Se encuentran también brotes y áreas
            extensas con plantas invasoras reconocidas como nocivas para los
            ecosistemas mexicanos. Las crecidas del río funcionan como factor de
            selección al remover la vegetación establecida sobre sedimentos.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>
            USOS HUMANOS DEL RÍO INVISIBILIZADOS Y SIN ATENCIÓN GUBERNAMENTAL.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Caminos que permiten cruzar, espacios de apreciación-recreación,
            vivienda aledaña y pernocta o refugio temporal son algunos de los
            usos humanos que el río permite y que carecen de medidas de
            seguridad adecuadas. El RSC tomado en cuenta como un hito del
            territorio, es una oportunidad para implementar la Estrategia
            Nacional de Ordenamiento Territorial con un sentido ecológico, de
            seguridad hídrica y cambio climático.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography>
            PERSPECTIVA DE DERECHOS AMBIENTALES AUSENTE EN LA PLANEACIÓN URBANA
            DE LA CIUDAD.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Los espacios naturales están en constante riesgo de afectación por
            las obras civiles y las dinámicas urbanas. Los ayuntamientos y el
            gobierno estatal desconocen los activos ambientales y cómo cuidarlos
            desde sus propios marcos regulatorios; en contraste promueven un
            urbanismo que arriesga la ciudadanía y la priva de un medio ambiente
            sano.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography>
            EL RSC ES UN REFUGIO PARA LA DIVERSIDAD BIOLÓGICA.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Interconecta Áreas Naturales Protegidas permitiendo conservar los
            servicios ambientales que aporta la biodiversidad. El RSC es casa de
            al menos 40 especies amenazadas (NOM-059-SEMARNAT-2010 y Lista roja
            de UICN) que han sido identificadas en un radio de 1 km alrededor
            del río; también es destino por temporada para especies migratorias.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography>SOLUCIONES BASADAS EN LA NATURALEZA.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Resaltamos la necesidad de restauración y conservación en todos los
            segmentos de la cuenca del RSC rehabilitando las funciones
            hidrológicas del ecosistema. Analizar el cambio en las comunidades
            vegetales y en los tipos de suelo por efecto del paso de la tormenta
            Alberto, permitirá determinar puntos prioritarios para intervenir
            con zonas inundables, humedales de saneamiento e infiltración,
            jardines de lluvia y otras soluciones basadas en la naturaleza.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
