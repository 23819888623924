import "./Filters.css";
import { useContext } from "react";
import { Select, InputLabel, MenuItem } from "@mui/material";
import { FiltersContext } from "../../contexts/FiltersContext";

export default function Filters() {
  const { filters, setFilters } = useContext(FiltersContext);

  function municipioUpdateHandler(e) {
    setFilters({
      ...filters,
      municipio: e.target.value,
    });
  }

  function tramoUpdateHandler(e) {
    if (e.target.value === "tu") {
      setFilters({
        ...filters,
        tu: 1,
        rh: 0,
      });
    } else {
      setFilters({
        ...filters,
        tu: 0,
        rh: 1,
      });
    }
  }

  return (
    <div className="filters_container">
      <div className="filter">
        <InputLabel>Selecciona un municipio:</InputLabel>
        <Select
          id="municipios"
          label="municipios"
          value={filters.municipio}
          onChange={municipioUpdateHandler}
        >
          <MenuItem value="MTY">Monterrey</MenuItem>
          <MenuItem value="GPE">Guadalupe</MenuItem>
          <MenuItem value="SPGG">San Pedro Garza García</MenuItem>
          <MenuItem value="SC">Santa Catarina</MenuItem>
        </Select>
      </div>
      <div className="filter">
        <InputLabel>Tramo</InputLabel>
        <Select
          id="tramos"
          label="tramos"
          value={filters.tu === 1 ? "tu" : "rh"}
          onChange={tramoUpdateHandler}
        >
          <MenuItem value="tu">Tramo Urbano</MenuItem>
          <MenuItem value="rh">Tramo Red Hidrográfica</MenuItem>
        </Select>
      </div>
      {/* <div className="filter">
        <InputLabel>Cuenca</InputLabel>
        <Select
          id="cuenca"
          label="cuenca"
          value={filters.cuenca}
          onChange={cuencaUpdateHandler}
        >
          <MenuItem value="alta">Cuenca Alta</MenuItem>
          <MenuItem value="media">Cuenca Media</MenuItem>
          <MenuItem value="baja">Cuenca Baja</MenuItem>
        </Select>
      </div> */}
    </div>
  );
}
