import { useState, useEffect } from "react";
import { supabaseClient } from "../config/supabase";
import { getParamsForRpc } from "./helper";

export function useGetPolygonParameter(filters, rpcFunctionName) {
  const [polygon, setPolygon] = useState();
  const [error, setError] = useState();

  async function getPolygonDynamic() {
    const params = getParamsForRpc(rpcFunctionName, filters);
    let { data, error } = await supabaseClient.rpc(rpcFunctionName, params);
    if (error) setError(error);
    else {
      setPolygon(data.features);
    }
  }

  useEffect(() => {
    // getPolygon();
    getPolygonDynamic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, rpcFunctionName]);

  if (error) {
    return error;
  } else {
    return polygon;
  }
}
