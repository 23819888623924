import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./TabPanel/TabPanel";
import ProblematicasAccordion from "./ProblematicasAccordion/ProblematicasAccordion";
import InvestigacionTable from "./InvestigacionTable/InvestigacionTable";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProjectDetailsSection() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs_rsc"
          scrollButtons="auto"
          allowScrollButtonsMobile
          variant="scrollable"
        >
          <Tab label="Contexto de proyecto" {...a11yProps(0)} />
          <Tab label="Investigación y metodología" {...a11yProps(1)} />
          <Tab label="Hallazgos (visibilizar el impacto)" {...a11yProps(2)} />
          <Tab label="Hipótesis y líneas de investigación" {...a11yProps(3)} />
          <Tab label="Documentación para descarga" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <p>
          SOSAC trabaja desde 2017 en el Río Santa Catarina involucrando a la
          ciudadanía en el mapeo de problemáticas ambientales y en la generación
          común de soluciones desde el cuidado del ecosistema urbano. Con las
          primeras visitas resultó claro que posterior al huracán Alex en 2010,
          el caudal canalizado del río comenzó a alojar un bosque típico de río
          en avance (sucesión biológica). <br></br> <br></br>Desde SOSAC,
          impulsamos la investigación-acción con el objetivo de cuidar y
          fomentar los servicios ambientales presentes en la ciudad, para ello
          caracterizamos el territorio y exploramos las relaciones ecológicas
          que permiten asegurar los derechos ambientales de la ciudadanía. Con
          estos primeros hallazgos podemos definir una ruta de acción
          diferenciada para cada segmento de la cuenca del río y articular
          acciones entre los distintos niveles de gobierno como modelo para
          asegurar cuerpos de agua saludables en la ciudad.
        </p>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <p>
            Entre septiembre de 2023 y abril de 2024, SOSAC coordinó un programa
            piloto para el estudio del tramo urbano del Río Santa Catarina (31
            Km). <br></br> <br></br>El tramo comprende desde la entrada del
            parque La Huasteca en el municipio de Santa Catarina, hasta el
            puente vehicular de Israel Cavazos en el municipio de Guadalupe.
            Entre las acciones que realizamos están:
          </p>
          <ol>
            <li>
              Recolección y separación de más de 74 ton de residuos abandonados
              en el río para analizar de dónde viene la basura.
            </li>
            <li>
              Mapeamos qué hay en el río: suelo y la vegetación existente y para
              realizar estrategias de reducción de riesgos y de mejora del
              ecosistema para cada condición.
            </li>
            <li>
              Entrevistamos a la población vecina para entender su conexión con
              el río y documentar las dinámicas positivas y capacidades
              presentes para cuidarlo y mejorarlo.
            </li>
          </ol>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
          <p>
            Conoce los principales hallazgos dando click sobre el título de la
            categoría que quieres conocer:
          </p>
          <ProblematicasAccordion />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InvestigacionTable />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <p>
          Con el objetivo de detonar nuevos esfuerzos de interpretación y
          análisis, así como un compromiso por la apertura de la información,
          SOSAC pone a disposición la documentación de referencia con la cual se
          generaron los presentes hallazgos:
        </p>
      </TabPanel>
    </Box>
  );
}
