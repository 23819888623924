import "./Navigation.css";
import logo from "../../images/riovivo_logo.png";
import logoSOSACC from "../../images/sosac_logo.png";

export default function Navigation() {
  return (
    <div className="navbar">
      <img src={logo} alt="RIOVIVO" className="logo" />
      <img src={logoSOSACC} alt="sosac" className="logo" />
    </div>
  );
}
