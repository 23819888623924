import { getTableName } from "../helpers/helpers.js";

const rpcParams = {
  get_points_dynamic: {
    data_name: "biodiversidad_RH24Bf_m",
    municipio_param: (filters) => getTableName(filters.municipio),
    tu_param: (filters) => filters.tu,
    amenazada_param: null,
    rh_param: null,
    grupo_param: null,
  },
  get_suelo_y_vegetacion: {
    municipio_param: (filters) => getTableName(filters.municipio),
  },
  get_denue_points: {
    municipio_param: (filters) => getTableName(filters.municipio),
  },
};

export function getParamsForRpc(rpcName, filters) {
  const paramsTemplate = rpcParams[rpcName];
  if (!paramsTemplate) {
    throw new Error(`No parameters defined for RPC function: ${rpcName}`);
  }

  const params = {};
  for (const key in paramsTemplate) {
    const value = paramsTemplate[key];
    params[key] = typeof value === "function" ? value(filters) : value;
  }

  return params;
}
