export const municipios = ["MTY", "GPE", "SPGG", "SC"];

const municipiosTables = [
  {
    id: 1,
    clave: "MTY",
    nombre: "Monterrey",
    table: "biodiversidad_monterrey",
  },
  {
    id: 2,
    clave: "GPE",
    nombre: "Guadalupe",
    table: "biodiversidad_guadalupe",
  },
  {
    id: 2,
    clave: "SPGG",
    nombre: "San Pedro Garza Garc",
    table: "biodiversidad_spgg",
  },
  {
    id: 2,
    clave: "SC",
    nombre: "Santa Catarina",
    table: "biodiversidad_santacatarina",
  },
];

export function getTableName(municipioCode) {
  var result = municipiosTables.filter((m) => {
    return m.clave === municipioCode;
  });

  return result[0].nombre;
}
