export const getColorForPolygon = (polygonName, feature) => {
  switch (polygonName) {
    case "tres_segmentos":
      if (feature.properties.segmento === "Cuenca alta") return "purple";
      else if (feature.properties.segmento === "Cuenca media") return "green";
      else return "red";
    case "anps_rh24Bf":
      return "#809c13";
    case "buffer_1km_tu":
      return "#03396c";
    default:
      return "#6497b1";
  }
};
